<template>
  <v-container class="white">
    <template v-if="article">
      <v-row class="mt-2">
        <v-col cols="12">
          <div class="text-button grey--text" v-if="article.topic">{{ article.topic.name }}</div>
          <div class="my-3 text-h5" v-html="article.title" />
          <v-list-item class="px-0">
            <v-list-item-content>
              <v-list-item-title v-text="article.author" />
              <v-list-item-subtitle v-text="formatPublished(article.published)" />
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon>
                <v-icon>mdi-export-variant</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-divider />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div align="center" class="pt-2 pb-6" v-if="article.subtitle">
            <v-icon color="grey lighten-1">mdi-format-quote-open</v-icon>
            <div class="text-subtitle-1 font-italic" v-html="article.subtitle" />
            <v-icon color="grey lighten-1">mdi-format-quote-close</v-icon>
          </div>
          <div class="py-3" v-for="(item, index) in article.images" :key="index">
            <v-img class="grey lighten-4" :src="item.url">
              <template v-slot:placeholder>
                <v-row align="center" justify="center" class="fill-height ma-0" />
              </template>
            </v-img>
            <div class="mt-1 text-center text-body-2 grey--text text--darken-2" v-html="item.desc" />
          </div>
          <div class="py-3 text-body-1 text-content" v-html="article.content" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0">
          <div>
            <v-divider />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="mb-8 scroll-button">
          <v-btn text tile plain block v-show="scroll" @click="scrollToTop()">
            <v-icon left>mdi-arrow-up</v-icon>
            맨 위로
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <v-row v-else-if="error">
      <v-container class="message-container">
        <v-row class="fill-height" align="center" justify="center">
          <v-col cols="12" class="text-center">
            <div class="text-h6 grey--text">조회 오류</div>
            <div class="mt-2 text-center" v-if="!loading">
              <v-btn color="primary" block @click="reload()">
                새로고침
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <template v-else>
      <v-row class="mt-14 mb-2">
        <v-col cols="12">
          <div>
            <v-skeleton-loader type="heading, heading, sentences" class="article-head-skeleton" />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-divider />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div align="center" class="pt-2 pb-6">
            <v-icon color="grey lighten-1">mdi-format-quote-open</v-icon>
            <v-skeleton-loader type="paragraph" />
            <v-icon color="grey lighten-1">mdi-format-quote-close</v-icon>
          </div>
          <div class="py-3">
            <v-skeleton-loader type="image, paragraph" class="article-body-skeleton" />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0">
          <div>
            <v-divider />
          </div>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  name: 'Article',
  metaInfo() {
    const article = this.article ? this.article : {};

    const title = article.title;
    const description = this.createDescription(article);
    const url = this.createUrl(article);

    const meta = Array();
    if (description) {
      meta.push({ name: 'description', content: description });
    }
    // OpenGraph
    meta.push({ property: 'og:title', content: title });
    meta.push({ property: 'og:type', content: 'article' });
    meta.push({ property: 'og:url', content: url });
    if (article.images) {
      for (const image of article.images) {
        meta.push({ property: 'og:image', content: image.url });
      }
    }
    if (description) {
      meta.push({ property: 'og:description', content: description });
    }
    if (article.author) {
      meta.push({ property: 'og:article:author', content: article.author });
    }

    return {
      title: title,
      titleTemplate: '%s | 서대문사람들',
      meta,
    };
  },
  data() {
    return {
      scroll: 0,
      scrollTimer: null,
    };
  },
  computed: {
    ...mapState ({
      loading: state => state.app.loading,
      article: state => state.articles.item,
      error: state => state.articles.itemError,
    }),
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },
  created() {
    this.fetchArticle();
  },
  methods: {
    ...mapActions('articles', [
      'getArticle',
    ]),
    ...mapMutations('articles', [
      'initializeItem',
    ]),
    reload() {
      console.log('reload');

      this.initializeItem();

      this.fetchArticle();
    },
    fetchArticle() {
      const id = this.$route.params.id;

      console.log('fetchArticle: id=', id);

      this.getArticle(id);
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    onScroll() {
      if (this.scrollTimer) {
        return;
      }

      this.scrollTimer = setTimeout(() => {
        this.scroll = window.scrollY;
        clearTimeout(this.scrollTimer);
        this.scrollTimer = null;
      }, 100);
    },
    formatPublished(date) {
      return date.substring(0, 16);
    },
    createDescription(article) {
      if (article.subtitle) {
        return article.subtitle.replace(/<[^>]*>?|(\r\n|\n|\r)/gm, '');
      }
    },
    createUrl(article) {
      if (this.$router.mode === 'history') {
        return `http://m.esdmnews.com/articles/${article.id}`
      } else {
        return `http://m.esdmnews.com/#/articles/${article.id}`
      }
    },
  },
};
</script>

<style scoped>
.message-container {
  height: calc(100vh - 56px);
}
.text-content {
  line-height: 1.75rem;
}
.scroll-button {
  min-height: 60px;
}
.article-head-skeleton >>> .v-skeleton-loader__heading:nth-child(1) {
  margin-bottom: 6px;
  width: 100%;
}
.article-head-skeleton >>> .v-skeleton-loader__sentences {
  margin-top: 24px;
}
.article-body-skeleton >>> .v-skeleton-loader__image {
  margin-bottom: 32px;
}
</style>
